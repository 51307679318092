import React, { useMemo } from "react"
import { makeStyles } from "@mui/styles"
import { GoogleMap, StreetViewPanorama } from "@react-google-maps/api"
import PropTypes from "prop-types"

const styles = {
  root: {
    width: "100%",
    backgroundColor: "",
  },
  map: {
    width: "100%",
    height: "100%",
  },
  streetView: {
    width: "800px",
    height: "480px",
  },
}

const DialogDetailContentDetailStreetView = (props) => {
  const center = useMemo(() => {
    return {
      lat: props.data.latitude ?? 35.6813706610132,
      lng: props.data.longitude ?? 139.76727819517552,
    }
  }, [props.data])

  return (
    <GoogleMap mapContainerStyle={styles.map} center={center} zoom={10}>
      <StreetViewPanorama
        mapContainerStyle={styles.streetView}
        position={center}
        visible={true}
        options={{
          enableCloseButton: false,
        }}
      />
    </GoogleMap>
  )
}

DialogDetailContentDetailStreetView.propTypes = {
  data: PropTypes.object,
}

export default DialogDetailContentDetailStreetView
