import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { ArrowRight as ArrowRightIcon } from "@mui/icons-material"
import {
  Alert,
  Box,
  Button,
  ListItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material"
import edited from "../../../../../street/left/table/renderer/edited"
import { Query, Rows } from "../../../../../../manager/carto"
import { AuthDataContext } from "../../../../../../App"
import { RootDataContext } from "../../../../index"
import BaseEditor from "./editor/base"
import SelectEditor from "./editor/select"
import TextEditor from "./editor/text"
import NumberEditor from "./editor/number"
import MultiNumberEditor from "./editor/multiNumber"
import MultiTextEditor from "./editor/multiText"
import CheckEditor from "./editor/check"
import AutocompleteEditor from "./editor/autocomplete"

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    height: "100%",
  },
  body: {
    overflowY: "scroll",
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    gap: 1,
  },
  footer: {
    height: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: "16px",
    marginRight: "32px",
  },
  row: {
    height: "50px",
    display: "flex",
    flexDirection: "row",
  },
  title: {
    width: "240px",
    height: "80%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 1,
  },
  title1: {
    backgroundColor: "#eee",
  },
  title2: {
    backgroundColor: "#eef",
  },
  value: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    paddingLeft: 1,
  },
  text_field: {
    marginBottom: 1,
  },
  editor: {
    rowBox: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    columnBox: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      minHeight: "42px",
      width: "480px",
    },
    labelBox: {
      width: "200px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "8px",
    },
    subLabel: {
      fontSize: "12px",
      color: "#575757",
      marginLeft: "8px",
    },
    leftLabelBox: {
      backgroundColor: "#d0d0d0",
    },
    rightLabelBox: {
      backgroundColor: "#ddddfa",
    },
    valueBox: {
      flexGrow: 1,
    },
    leftValueBox: {
      display: "flex",
      alignItems: "center",
    },
  },
}

const DialogDetailContentEditView = (props) => {
  const { authState } = useContext(AuthDataContext)
  const { state, setRefreshTime } = useContext(RootDataContext)
  const [editedData, setEditedData] = useState({})
  const [isChanged, setIsChanged] = useState(false)
  const [updateSuccessful, setUpdateSuccessful] = useState(false)

  const [variants, setVariants] = useState([])
  const [names, setNames] = useState([])

  const calcPerimeter = useMemo(() => {
    if (!editedData) {
      return null
    }

    let v = [
      editedData.perimeter_1,
      editedData.perimeter_2,
      editedData.perimeter_3,
      editedData.perimeter_4,
      editedData.perimeter_5,
      editedData.perimeter_6,
      editedData.perimeter_7,
      editedData.perimeter_8,
      editedData.perimeter_9,
      editedData.perimeter_10,
    ].filter((v) => v && parseInt(v) !== 0)
    if (v.length === 0) {
      return null
    }
    if (v.length === 1) {
      return v[0]
    }
    return v.reduce((s, i) => s + parseInt(i), 0) * 0.7
  }, [editedData])
  const currentCalcPerimeter = useMemo(() => {
    if (!props.data) {
      return null
    }

    let v = [
      props.data.perimeter_1,
      props.data.perimeter_2,
      props.data.perimeter_3,
      props.data.perimeter_4,
      props.data.perimeter_5,
      props.data.perimeter_6,
      props.data.perimeter_7,
      props.data.perimeter_8,
      props.data.perimeter_9,
      props.data.perimeter_10,
    ].filter((v) => v && parseInt(v) !== 0)
    if (v.length === 0) {
      return null
    }
    if (v.length === 1) {
      return v[0]
    }
    return v.reduce((s, i) => s + parseInt(i), 0) * 0.7
  }, [props.data])

  const fullWidth2HalfWidth = (src) => {
    return src.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
  }

  useEffect(() => {})

  useEffect(() => {
    Rows(
      `SELECT DISTINCT variant FROM ${process.env.REACT_APP_TREE_VIEW} WHERE variant IS NOT NULL ORDER BY variant`
    ).then((rows) => {
      setVariants(
        rows?.map((r) => {
          return { label: r.variant }
        }) ?? []
      )
    })
    Rows(
      `SELECT DISTINCT name FROM ${process.env.REACT_APP_TREE_VIEW} WHERE name IS NOT NULL ORDER BY name`
    ).then((rows) => {
      setNames(
        rows?.map((r) => {
          return { label: r.name }
        }) ?? []
      )
    })
  }, [state.refreshTime])

  const perimeters = useMemo(() => {
    if (!editedData) {
      return []
    }
    return [
      editedData.perimeter_1,
      editedData.perimeter_2,
      editedData.perimeter_3,
      editedData.perimeter_4,
      editedData.perimeter_5,
      editedData.perimeter_6,
      editedData.perimeter_7,
      editedData.perimeter_8,
      editedData.perimeter_9,
      editedData.perimeter_10,
    ].filter((v) => v && parseInt(v) !== 0)
  }, [editedData])
  const currentPerimeters = useMemo(() => {
    if (!props.data) {
      return []
    }
    return [
      props.data.perimeter_1,
      props.data.perimeter_2,
      props.data.perimeter_3,
      props.data.perimeter_4,
      props.data.perimeter_5,
      props.data.perimeter_6,
      props.data.perimeter_7,
      props.data.perimeter_8,
      props.data.perimeter_9,
      props.data.perimeter_10,
    ].filter((v) => v && parseInt(v) !== 0)
  }, [props.data])

  const teibokushu = useMemo(() => {
    if (!editedData) {
      return []
    }
    return [
      editedData.teiboku_name_1,
      editedData.teiboku_name_2,
      editedData.teiboku_name_3,
      editedData.teiboku_name_4,
      editedData.teiboku_name_5,
      editedData.teiboku_name_6,
      editedData.teiboku_name_7,
      editedData.teiboku_name_8,
      editedData.teiboku_name_9,
      editedData.teiboku_name_10,
      editedData.teiboku_name_11,
      editedData.teiboku_name_12,
      editedData.teiboku_name_13,
    ].filter((v) => v && v.trim().length > 0)
  }, [editedData])

  const currentTeibokushu = useMemo(() => {
    if (props.data) {
      return [
        props.data.teiboku_name_1,
        props.data.teiboku_name_2,
        props.data.teiboku_name_3,
        props.data.teiboku_name_4,
        props.data.teiboku_name_5,
        props.data.teiboku_name_6,
        props.data.teiboku_name_7,
        props.data.teiboku_name_8,
        props.data.teiboku_name_9,
        props.data.teiboku_name_10,
        props.data.teiboku_name_11,
        props.data.teiboku_name_12,
        props.data.teiboku_name_13,
      ].filter((v) => v && v.trim().length > 0)
    }
    return []
  }, [props.data])

  useEffect(() => {
    if (!props.data) return
    setEditedData(props.data)
  }, [props.data])

  useEffect(() => {
    setIsChanged(
      sortedJSONStringify(props.data) !== sortedJSONStringify(editedData)
    )
  }, [editedData])

  useEffect(() => {}, [editedData])

  const sortedJSONStringify = (obj) =>
    JSON.stringify(obj, (_, v) =>
      !(v instanceof Array || v === null) && typeof v == "object"
        ? Object.keys(v)
            .sort()
            .reduce((r, k) => {
              r[k] = v[k]
              return r
            }, {})
        : v
    )

  const setTextValue = (val, nullWhen0Length = true) => {
    if (val === undefined) {
      return "NULL"
    }
    if (val === null) {
      return "NULL"
    }
    if (!val) {
      return "NULL"
    }
    if (nullWhen0Length && val.length === 0) {
      return "NULL"
    }
    console.log(val)
    val = String(val).replace("'", "").replace(";", "")
    return `'${val}'`
  }

  const setIntValue = (val, nullWhenZero = false) => {
    if (val === null) {
      return "NULL"
    }
    if (val === undefined) {
      return "NULL"
    }
    if (val.length === 0) {
      return "NULL"
    }
    if (val === false) {
      return "NULL"
    }
    if (nullWhenZero && val === 0) {
      return "NULL"
    }
    return parseInt(val)
  }

  const setFloatValue = (val, toFixed = null, nullWhenZero = false) => {
    if (val === null) {
      return "NULL"
    }
    if (val === undefined) {
      return "NULL"
    }
    if (val.length === 0) {
      return "NULL"
    }
    if (val === false) {
      return "NULL"
    }
    if (nullWhenZero && val == 0) {
      return "NULL"
    }
    if (toFixed) {
      return parseFloat(val).toFixed(toFixed)
    }
    return parseFloat(val)
  }

  const updateData = () => {
    if (!editedData) {
      return
    }
    let data = {}
    data["cartodb_id"] = editedData["cartodb_id"]
    data[
      "the_geom"
    ] = `ST_SetSRID(ST_MakePoint(${editedData["longitude"]},${editedData["latitude"]}),4326)`
    data["the_geom_webmercator"] = `ST_Transform(${data["the_geom"]},3857)`
    data["fid"] = setIntValue(editedData["fid"])
    data["id"] = setIntValue(editedData["id"])
    data["tree_id"] = setTextValue(editedData["tree_id"])
    data["temp_tree_id"] = setTextValue(editedData["temp_tree_id"])
    data["citycode"] = setTextValue(editedData["citycode"])
    data["map_name"] = setTextValue(editedData["map_name"])
    data["date"] = setTextValue(editedData["date"])
    data["author"] = setTextValue(editedData["author"])
    data["route_number"] = setIntValue(editedData["route_number"])
    data["type"] = setTextValue(editedData["type"])
    data["name"] = setTextValue(editedData["name"])
    data["variant"] = setTextValue(editedData["variant"])
    data["perimeter_1"] = setIntValue(editedData["perimeter_1"])
    data["perimeter_2"] = setIntValue(editedData["perimeter_2"])
    data["perimeter_3"] = setIntValue(editedData["perimeter_3"])
    data["perimeter_4"] = setIntValue(editedData["perimeter_4"])
    data["perimeter_5"] = setIntValue(editedData["perimeter_5"])
    data["perimeter_6"] = setIntValue(editedData["perimeter_6"])
    data["perimeter_7"] = setIntValue(editedData["perimeter_7"])
    data["perimeter_8"] = setIntValue(editedData["perimeter_8"])
    data["perimeter_9"] = setIntValue(editedData["perimeter_9"])
    data["perimeter_10"] = setIntValue(editedData["perimeter_10"])
    data["height"] = setFloatValue(editedData["height"])
    data["width"] = setFloatValue(editedData["width"])
    data["teiboku_name_1"] = setTextValue(editedData["teiboku_name_1"])
    data["teiboku_name_2"] = setTextValue(editedData["teiboku_name_2"])
    data["teiboku_name_3"] = setTextValue(editedData["teiboku_name_3"])
    data["teiboku_name_4"] = setTextValue(editedData["teiboku_name_4"])
    data["teiboku_name_5"] = setTextValue(editedData["teiboku_name_5"])
    data["teiboku_name_6"] = setTextValue(editedData["teiboku_name_6"])
    data["teiboku_name_7"] = setTextValue(editedData["teiboku_name_7"])
    data["teiboku_name_8"] = setTextValue(editedData["teiboku_name_8"])
    data["teiboku_name_9"] = setTextValue(editedData["teiboku_name_9"])
    data["teiboku_name_10"] = setTextValue(editedData["teiboku_name_10"])
    data["teiboku_name_11"] = setTextValue(editedData["teiboku_name_11"])
    data["teiboku_name_12"] = setTextValue(editedData["teiboku_name_12"])
    data["teiboku_name_13"] = setTextValue(editedData["teiboku_name_13"])
    data["teiboku_name_14"] = setTextValue(editedData["teiboku_name_14"])
    data["hedge_length"] = setFloatValue(editedData["hedge_length"])
    data["pit_number"] = setIntValue(editedData["pit_number"])
    data["is_dead"] = setIntValue(editedData["is_dead"])
    data["suggested_type"] = setTextValue(editedData["suggested_type"])
    data["is_hedge"] = setIntValue(editedData["is_hedge"])
    data["route_remarks"] = setTextValue(editedData["route_remarks"])
    data["tree_number"] = setIntValue(editedData["tree_number"])
    data["map_number"] = setIntValue(editedData["map_number"])
    data["pit_id"] = setTextValue(editedData["pit_id"])
    data["teiboku_remarks"] = setTextValue(editedData["teiboku_remarks"])
    data["remarks"] = setTextValue(editedData["remarks"])
    data["is_mytree"] = setIntValue(editedData["is_mytree"])
    data["outofscope"] = setTextValue(editedData["outofscope"])
    data["hedge_count"] = setTextValue(editedData["hedge_count"])
    data["tree_count"] = setTextValue(editedData["tree_count"])
    data["side"] = setTextValue(editedData["side"])
    data["route_type"] = setTextValue(editedData["route_type"])
    data["photos"] = setTextValue(editedData["photos"])
    data["no"] = setIntValue(editedData["no"])
    data["perimeter"] = setFloatValue(calcPerimeter)
    data["diagnostic_pdf"] = setTextValue(editedData["diagnostic_pdf"])
    data["office"] = setTextValue(editedData["office"])
    data["route_name"] = setTextValue(editedData["route_name"])
    data["area_name"] = setTextValue(editedData["area_name"])
    data["created_at"] = "NOW()"
    data["created_user_id"] = setTextValue(authState.userData.detail.cartodb_id)

    let query = `
        INSERT INTO tokyo_roadsidetrees_edited
        (${Object.keys(data).join(", ")}) VALUES
        (${Object.values(data).join(",")})
    `

    try {
      Query(query)
        .then((res) => {
          setRefreshTime()
          setUpdateSuccessful(true)
        })
        .catch((e) => {
          console.log("Edit query error", e)
        })
    } catch (e) {
      console.log("Edit query exception", e)
    }
  }

  return (
    <Box sx={styles.root}>
      <Box
        sx={{
          height: "calc(100% - 48px)",
          overflowY: "scroll",
          margin: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          paddingTop: "8px",
        }}
      >
        <SelectEditor
          id="side"
          label="路線左右"
          current={props.data.side}
          value={editedData.side}
          options={[
            { name: "L", value: "L" },
            { name: "R", value: "R" },
            { name: "C", value: "C" },
            { name: "O", value: "O" },
            { name: "X", value: "X" },
          ]}
          onChange={(value) => setEditedData({ ...editedData, side: value })}
          styles={styles.editor}
        />
        <SelectEditor
          id="type"
          label="樹木タイプ"
          current={props.data.type}
          value={editedData.type}
          options={[
            { name: "高木", value: "高木" },
            { name: "中木", value: "中木" },
            { name: "低木", value: "低木" },
            { name: "シバ", value: "シバ" },
          ]}
          onChange={(value) => setEditedData({ ...editedData, type: value })}
          styles={styles.editor}
        />
        <TextEditor
          id="name"
          label="樹種名"
          current={props.data.name}
          value={editedData.name}
          onChange={(value) => setEditedData({ ...editedData, name: value })}
          styles={styles.editor}
        />
        <TextEditor
          id="variant"
          label="品種名"
          current={props.data.variant}
          value={editedData.variant}
          onChange={(value) => setEditedData({ ...editedData, variant: value })}
          styles={styles.editor}
        />
        <NumberEditor
          id="height"
          label="樹高"
          subLabel="0.5m単位"
          current={props.data?.height ? `${props.data.height} m` : ""}
          value={editedData.height}
          onChange={(value) =>
            setEditedData({
              ...editedData,
              height: value,
            })
          }
          onBlur={() => {
            console.log(editedData.height)
            setEditedData({
              ...editedData,
              height: parseFloat(editedData.height).toFixed(1),
            })
          }}
          unitName="m"
          styles={styles.editor}
        />
        <BaseEditor
          id="calc_perimeter"
          label="幹周(自動計算)"
          current={
            currentCalcPerimeter ? `${currentCalcPerimeter.toFixed(1)} cm` : ""
          }
          styles={{ ...styles.editor, rightValueBox: { alignItems: "center" } }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>
              {calcPerimeter ? `${calcPerimeter.toFixed(1)} cm` : ""}
            </Typography>
          </Box>
        </BaseEditor>
        <MultiNumberEditor
          id="perimeter"
          label="幹周"
          unitName="cm"
          max={10}
          current={currentPerimeters.map((v, i) => (
            <Typography key={`perimeter-current-value-${i}`}>
              幹周{i + 1}：{parseInt(v)} cm
            </Typography>
          ))}
          values={perimeters}
          onChange={(values) => {
            setEditedData({
              ...editedData,
              perimeter_1: values.length > 0 ? values[0] : null,
              perimeter_2: values.length > 1 ? values[1] : null,
              perimeter_3: values.length > 2 ? values[2] : null,
              perimeter_4: values.length > 3 ? values[3] : null,
              perimeter_5: values.length > 4 ? values[4] : null,
              perimeter_6: values.length > 5 ? values[5] : null,
              perimeter_7: values.length > 6 ? values[6] : null,
              perimeter_8: values.length > 7 ? values[7] : null,
              perimeter_9: values.length > 8 ? values[8] : null,
              perimeter_10: values.length > 9 ? values[9] : null,
            })

            console.log("multi number", "on blur", values)
          }}
          styles={{
            ...styles.editor,
            leftValueBox: {
              ...styles.editor.leftValueBox,
              alignItems: "start",
              paddingTop: "4px",
            },
          }}
        />
        <NumberEditor
          id="width"
          label="枝張"
          subLabel="0.5m単位"
          current={props.data?.width ? `${props.data.width} m` : ""}
          value={editedData.width}
          onChange={(value) =>
            setEditedData({
              ...editedData,
              width: value,
            })
          }
          onBlur={() => {
            setEditedData({
              ...editedData,
              width: parseFloat(editedData.width).toFixed(1),
            })
          }}
          unitName="m"
          styles={styles.editor}
        />
        <NumberEditor
          id="tree_count"
          label="本数"
          current={props.data?.tree_count ? `${props.data.tree_count}本` : ""}
          value={editedData.tree_count}
          onChange={(value) =>
            setEditedData({
              ...editedData,
              tree_count: value,
            })
          }
          onBlur={() => {
            setEditedData({
              ...editedData,
              tree_count: parseInt(editedData.tree_count),
            })
          }}
          unitName="本"
          styles={styles.editor}
        />
        <MultiTextEditor
          id="teiboku"
          label="低木種"
          max={13}
          current={currentTeibokushu.map((v, i) => (
            <Typography key={`teiboku-current-value-${i}`}>
              低木種{i + 1}：{v}
            </Typography>
          ))}
          values={teibokushu}
          onChange={(values) => {
            setEditedData({
              ...editedData,
              teiboku_name_1: values.length > 0 ? values[0] : null,
              teiboku_name_2: values.length > 1 ? values[1] : null,
              teiboku_name_3: values.length > 2 ? values[2] : null,
              teiboku_name_4: values.length > 3 ? values[3] : null,
              teiboku_name_5: values.length > 4 ? values[4] : null,
              teiboku_name_6: values.length > 5 ? values[5] : null,
              teiboku_name_7: values.length > 6 ? values[6] : null,
              teiboku_name_8: values.length > 7 ? values[7] : null,
              teiboku_name_9: values.length > 8 ? values[8] : null,
              teiboku_name_10: values.length > 9 ? values[10] : null,
              teiboku_name_11: values.length > 9 ? values[11] : null,
              teiboku_name_12: values.length > 9 ? values[12] : null,
              teiboku_name_13: values.length > 9 ? values[13] : null,
            })
          }}
          styles={{
            ...styles.editor,
            leftValueBox: {
              ...styles.editor.leftValueBox,
              alignItems: "start",
              paddingTop: "4px",
            },
          }}
        />
        <TextEditor
          id="teiboku_remarks"
          label="低木種注記"
          current={props.data.teiboku_remarks}
          value={editedData.teiboku_remarks}
          onChange={(value) =>
            setEditedData({ ...editedData, teiboku_remarks: value })
          }
          styles={styles.editor}
        />
        <CheckEditor
          id="is_hedge"
          label="生垣仕立て"
          current={props.data.is_hedge === 1 ? "はい" : ""}
          checked={editedData.is_hedge === 1}
          onChange={(value) =>
            setEditedData({ ...editedData, is_hedge: value ? 1 : null })
          }
          styles={styles.editor}
        />
        {editedData.is_hedge && (
          <NumberEditor
            id="hedge_length"
            label="生垣長"
            subLabel="0.5m単位"
            current={
              props.data?.hedge_length ? `${props.data.hedge_length} m` : ""
            }
            value={editedData.hedge_length}
            onChange={(value) =>
              setEditedData({
                ...editedData,
                hedge_length: value,
              })
            }
            onBlur={() => {
              setEditedData({
                ...editedData,
                hedge_length: parseFloat(editedData.hedge_length).toFixed(1),
              })
            }}
            unitName="m"
            styles={styles.editor}
          />
        )}
        {editedData.is_hedge && (
          <NumberEditor
            id="hedge_count"
            label="生垣本数"
            current={
              props.data?.hedge_count ? `${props.data.hedge_count} 本` : ""
            }
            value={editedData.hedge_count}
            onChange={(value) =>
              setEditedData({
                ...editedData,
                hedge_count: value,
              })
            }
            onBlur={() => {
              setEditedData({
                ...editedData,
                hedge_count: parseInt(editedData.hedge_count),
              })
            }}
            unitName="本"
            styles={styles.editor}
          />
        )}
        <TextEditor
          id="remarks"
          label="注記"
          current={props.data.remarks}
          value={editedData.remarks}
          onChange={(value) => setEditedData({ ...editedData, remarks: value })}
          styles={styles.editor}
        />
        <CheckEditor
          id="is_mytree"
          label="マイツリータグ"
          current={props.data.is_mytree === 1 ? "ある" : ""}
          checked={editedData.is_mytree === 1}
          onChange={(value) =>
            setEditedData({ ...editedData, is_mytree: value ? 1 : null })
          }
          styles={styles.editor}
        />
        <CheckEditor
          id="is_dead"
          label="枯損"
          current={props.data.is_dead === 1 ? "枯れ" : ""}
          checked={editedData.is_dead === 1}
          onChange={(value) =>
            setEditedData({ ...editedData, is_dead: value ? 1 : null })
          }
          styles={styles.editor}
        />
      </Box>
      <Box style={styles.footer}>
        <Button
          variant="contained"
          disabled={!isChanged}
          onClick={() => setRefreshTime()}
        >
          リセット
        </Button>
        <Button variant="contained" disabled={!isChanged} onClick={updateData}>
          保存
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={updateSuccessful}
        autoHideDuration={3000}
        onClose={() => setUpdateSuccessful(false)}
      >
        <Alert severity="success">更新しました</Alert>
      </Snackbar>
    </Box>
  )
}

DialogDetailContentEditView.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.object,
}

export default DialogDetailContentEditView
