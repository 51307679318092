import React, { useContext, useEffect, useRef, useState } from "react"
import { makeStyles } from "@mui/styles"
import { Box, Button, Divider, IconButton, Typography } from "@mui/material"
import ChuteibokuTable from "./table/chuteboku"
import KobokuTable from "./table/koboku"
import GaikanShindanTable from "./table/gaikanShindan"
import KikiShindanTable from "./table/kikiShindan"
import { Close as CloseIcon } from "@mui/icons-material"
import PropTypes from "prop-types"
import GairojuManager from "../../../../manager/gairoju"
import {
  ExportSummaryExcel,
  exportSummaryExcel,
} from "../../../../manager/excel"
import { RootDataContext } from "../../index"

const useStyles = makeStyles({
  root: {
    display: "flex",
    color: "inherit",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  dialog: {
    padding: "8px",
    minWidth: "500",
    width: "70%",
    minHeight: "500",
    height: "70%",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "1px 8px 12px 0 rgba(0,0,0, .5)",
  },
  title: {
    height: "20px",
  },
  content: {
    height: "calc(100% - 48px)",
    display: "flex",
    flexDirection: "column",
  },
})

const RootDialogSummaryView = (props) => {
  const classes = useStyles()
  const { state } = useContext(RootDataContext)
  const [kobokuData, setKobokuData] = useState([])
  const [chutebokuData, setChutebokuData] = useState([])
  const [gaikanShindanData, setGaikanShindanData] = useState([])
  const [kikiShindanData, setKikiShindanData] = useState([])

  useEffect(() => {
    GairojuManager.kobokuSummaryQuery(state.filterData).then(setKobokuData)
    GairojuManager.chuteibokuSummaryQuery(state.filterData).then(
      setChutebokuData
    )
    GairojuManager.gaikanShindanSummaryQuery(state.filterData).then(
      setGaikanShindanData
    )
    GairojuManager.kikiShindanSummaryQuery(state.filterData).then(
      setKikiShindanData
    )
  }, [state.filterData])

  const onExcelExport = async () => {
    await exportSummaryExcel(
      kobokuData,
      chutebokuData,
      gaikanShindanData,
      kikiShindanData
    )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.dialog}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "30px",
            marginLeft: "8px",
          }}
        >
          <Typography variant="h6">ランク集計表</Typography>
          <Box flexGrow={1} />
          <Button variant="outlined" size="small" onClick={onExcelExport}>
            Excel出力
          </Button>
          <Box sx={{ m: 1 }} />
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider style={{ margin: "8px" }} />
        <Box className={classes.content}>
          <Box
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <Typography style={{ marginLeft: "16px" }} fontSize="small">
              高木幹周ランク集計表
            </Typography>
            <KobokuTable data={kobokuData} style={{ flexGrow: 1 }} />
          </Box>
          <Box
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <Typography
              style={{ marginTop: "8px", marginLeft: "16px" }}
              fontSize="small"
            >
              中低木高さランク集計表
            </Typography>
            <ChuteibokuTable data={chutebokuData} style={{ flexGrow: 1 }} />
          </Box>
          <Box
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <Typography
              style={{ marginTop: "8px", marginLeft: "16px" }}
              fontSize="small"
            >
              外観診断ランク集計表
            </Typography>
            <GaikanShindanTable
              data={gaikanShindanData}
              style={{ flexGrow: 1 }}
            />
          </Box>
          <Box
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <Typography
              style={{ marginTop: "8px", marginLeft: "16px" }}
              fontSize="small"
            >
              機器診断ランク集計表
            </Typography>
            <KikiShindanTable data={kikiShindanData} style={{ flexGrow: 1 }} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

RootDialogSummaryView.propTypes = {
  onClose: PropTypes.func,
}

export default RootDialogSummaryView
