import PropTypes from "prop-types"
import { Box, ButtonGroup, IconButton } from "@mui/material"
import Map from "./map"
import StreetView from "./streetview"
import React, { useState } from "react"
import Datatable from "./datatable"
import { Boy as BoyIcon, Map as MapIcon } from "@mui/icons-material"

const MapMode = {
  Map: "map",
  StreetView: "street_view",
}

const styles = {
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    overflowY: "scroll",
    gap: 8,
    position: "relative",
  },
  mapModeBox: {
    position: "absolute",
    top: "4rem",
    right: "0.6rem",
    zIndex: 500,
    background: "white",
  },
}

const DialogDetailContentDetailView = (props) => {
  const [mapMode, setMapMode] = useState(MapMode.Map)

  return (
    <Box style={styles.root}>
      <Datatable data={props.data} sx={{ minWidth: "400px" }} />
      <Box style={{ flexGrow: 1, position: "relative" }}>
        {mapMode === MapMode.Map && <Map data={props.data} />}
        {mapMode === MapMode.StreetView && <StreetView data={props.data} />}
        <Box style={styles.mapModeBox}>
          <ButtonGroup>
            <IconButton
              onClick={() =>
                mapMode === MapMode.StreetView && setMapMode(MapMode.Map)
              }
            >
              <MapIcon
                style={{
                  color: mapMode === MapMode.Map ? "#9974ff" : "#c7c7c7",
                }}
              />
            </IconButton>
            <IconButton
              onClick={() =>
                mapMode === MapMode.Map && setMapMode(MapMode.StreetView)
              }
            >
              <BoyIcon
                style={{
                  color: mapMode === MapMode.StreetView ? "#9974ff" : "#c7c7c7",
                }}
              />
            </IconButton>
          </ButtonGroup>
        </Box>
      </Box>
    </Box>
  )
}

DialogDetailContentDetailView.propTypes = {
  className: PropTypes.string,
  data: PropTypes.any,
}

export default DialogDetailContentDetailView
