import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import {
  Autocomplete,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Popper,
  TextField,
  Typography,
} from "@mui/material"
import { useState } from "react"
import axios from "axios"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  root: {
    width: "480px",
  },
  textField: {
    width: "100%",
    height: "100%",
  },
  label: {
    display: "flex",
    flexDirection: "column",
  },
  labelTitle: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  labelSubTitle: {
    fontSize: "6px",
  },
  pop: {
    zIndex: 10000,
    width: "480px",
    backgroundColor: "white",
  },
})

const styles = {
  listLabel: {
    fontSize: "12px",
    marginLeft: "1rem",
    marginBottom: "-1rem",
    color: "#999",
  },
  listItem: {
    padding: 0,
    marginLeft: "1rem",
  },
  emptyItem: {
    marginLeft: "1rem",
    marginTop: "1rem",
    color: "#9f9f9f",
  },
}

const MapControlPlaceSearch = (props) => {
  const classes = useStyles()
  const [options, setOptions] = useState(null)
  const textFieldRef = useRef(null)
  const inputRef = useRef(null)
  const [value, setValue] = useState()

  useEffect(() => {
    if (!value?.length) {
      setOptions(null)
      return
    }

    let tm = setTimeout(() => {
      Promise.all([
        (async () => {
          let res = await axios({
            method: "get",
            url: "https://dev.virtualearth.net/REST/v1/Locations",
            params: {
              query: value,
              c: "ja-jp",
              key: process.env.REACT_APP_BING_API_KEY,
            },
          })
          if (!res.data?.resourceSets?.length) {
            return ["bing", null]
          }

          console.log(res.data.resourceSets)

          return [
            "bing",
            res.data.resourceSets[0].resources
              .map((v) => {
                console.log(v)
                if (v.address.adminDistrict !== "東京都") {
                  return null
                }
                let addresses = []
                v.address?.locality && addresses.push(v.address.locality)
                v.address?.addressLine && addresses.push(v.address.addressLine)
                const txt =
                  v.name ?? v.address.formattedAddress ?? v.address?.locality
                let address = addresses.length ? addresses.join("") : null
                if (address === txt) {
                  address = null
                }
                return {
                  text: txt ?? address,
                  place_name: address,
                  center: v.point.coordinates,
                }
              })
              .filter((v) => !!v),
          ]
        })(),
        (async () => {
          let res = await axios({
            method: "post",
            url: process.env.REACT_APP_PLACE_SEARCH_ENDPOINT,
            data: {
              text: value,
            },
          })
          console.log("[PlaceApi]", res.data)
          if (!res.data?.rows?.length) {
            return ["place", null]
          }
          return ["place", res.data.rows]
        })(),
      ])
        .then((vals) => {
          setOptions(Object.fromEntries(vals))
        })
        .catch((e) => {
          setOptions(null)
        })
    }, 500)

    return () => {
      clearTimeout(tm)
    }
  }, [value])

  useEffect(() => {
    console.log("[Change options]", options)
  }, [options])

  const onSelect = (e, value) => {
    props.onPlaceSelected(value)
  }

  const onListItemClicked = (value) => {
    setOptions(null)
    props.onPlaceSelected(value)
  }

  return (
    <>
      <Box className={classes.root + " " + props.className}>
        <TextField
          className={classes.textField}
          label="住所・名称などで検索"
          autoComplete="off"
          ref={textFieldRef}
          inputRef={inputRef}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Popper
          className={classes.pop}
          id="search_result"
          open={!!options}
          anchorEl={textFieldRef.current}
          placement="bottom-start"
          modifiers={[
            {
              name: "preventOverflow",
              options: {
                altAxis: true, // Y軸でのオーバーフローを防ぐ
                altBoundary: true,
                tether: false,
                rootBoundary: "viewport",
                padding: 8, // ビューポートの境界との最小距離
              },
            },
          ]}
        >
          <List style={{ maxHeight: "50%", overflowY: "auto" }}>
            <Typography style={styles.listLabel}>住所</Typography>
            {options?.place?.map((v, i) => {
              if (i > 5) {
                return null
              }
              if (i === 5 && options.place.length > 5) {
                return (
                  <Typography
                    style={{
                      marginLeft: "3rem",
                      fontSize: "14px",
                      color: "#666",
                      marginBottom: "1rem",
                    }}
                  >
                    {options.place.length - 5}件を省略
                  </Typography>
                )
              }
              return (
                <ListItem key={v.text} style={styles.listItem}>
                  <ListItemButton onClick={() => onListItemClicked(v)}>
                    <ListItemText primary={v.text} secondary={v.place_name} />
                  </ListItemButton>
                </ListItem>
              )
            })}
            {!options?.place?.length && (
              <ListItem style={styles.emptyItem}>
                <ListItemText>結果なし</ListItemText>
              </ListItem>
            )}
            <Typography style={styles.listLabel}>建物等</Typography>
            {options?.bing?.map((v) => {
              return (
                <ListItem key={v.text} style={styles.listItem}>
                  <ListItemButton onClick={() => onListItemClicked(v)}>
                    <ListItemText primary={v.text} secondary={v.place_name} />
                  </ListItemButton>
                </ListItem>
              )
            })}
            {!options?.bing?.length && (
              <ListItem style={styles.emptyItem}>
                <ListItemText>結果なし</ListItemText>
              </ListItem>
            )}
          </List>
        </Popper>
      </Box>
    </>
  )
}

MapControlPlaceSearch.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
  onPlaceSelected: PropTypes.func,
}

export default MapControlPlaceSearch
