import React, { useEffect, useMemo, useState } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Root from "./views/root"
import Map from "./views/root/map"
import Street from "./views/street"
import ResizeTest from "./views/street/resizeTest"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import UseAuthData from "./auth"
import LoginView from "./views/login"
import firebase from "firebase/compat/app"
import { StyledFirebaseAuth } from "react-firebaseui"
import "firebase/compat/auth"
import { Box, CircularProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { First, One } from "./manager/carto"
import "dayjs/locale/ja"
import dayjs from "dayjs"
import { LoadScript } from "@react-google-maps/api"
export const AuthDataContext = React.createContext()

dayjs.locale("ja")

const useStyles = makeStyles({
  circle: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: "#333",
  },
})

const App = () => {
  const classes = useStyles()
  const useAuthData = UseAuthData()
  const { authState, setAuth, login } = useAuthData
  const [authInitialized, setAuthInitialized] = useState(false)

  useEffect(() => {
    // filter_data特別対応
    if (!localStorage.getItem("_reset_20240108")) {
      localStorage.removeItem("filter_data")
      localStorage.setItem("_reset_20240108", "1")
    }
  }, [])

  useMemo(() => {
    return setAuth(getAuth())
  }, [])

  useEffect(() => {
    if (!authState.auth) {
      return
    }
    onAuthStateChanged(authState.auth, (user) => {
      if (user && user.email) {
        First(
          `SELECT * FROM tokyo_g_users WHERE LOWER(email) = '${user.email.toLowerCase()}'`
        )
          .then((res) => {
            login({ ...user, detail: res })
          })
          .catch((e) => {
            login({ ...user, detail: {} })
          })
      } else if (user) {
        login({ ...user, detail: {} })
      } else {
        login(user)
      }

      setAuthInitialized(true)
    })
  }, [authState.auth])

  return (
    <AuthDataContext.Provider value={useAuthData}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
        {!authInitialized && (
          <Box className={classes.circle}>
            <CircularProgress color="success" />
          </Box>
        )}
        {authInitialized && !authState.userData && <LoginView />}
        {authInitialized && authState.userData && (
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Root />} />
              <Route path="/map" element={<Map />} />
              <Route path="/street" element={<Street />} />
              <Route path="/test" element={<ResizeTest />} />
            </Routes>
          </BrowserRouter>
        )}
      </LoadScript>
    </AuthDataContext.Provider>
  )
}

export default App
