import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material"
import RichTextEditor from "react-rte"
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Send as SendIcon,
} from "@mui/icons-material"
import { RootDataContext } from "../../../../index"
import { Query, Rows } from "../../../../../../manager/carto"
import { AuthDataContext } from "../../../../../../App"
import dayjs from "dayjs"
import "../content.css"

const Category = ["その他", "落葉・実", "害虫", "視距・建築限界", "マイツリー"]

const DialogDetailContentCommentView = (props) => {
  const { authState } = useContext(AuthDataContext)
  const { state, setRefreshTime } = useContext(RootDataContext)
  const [value, setValue] = useState()
  const [category, setCategory] = useState()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const listRef = useRef(null)
  const messageEndRef = useRef(null)
  const [editCartodbId, setEditCartodbId] = useState()
  const [editValue, setEditValue] = useState()
  const [editCategory, setEditCategory] = useState()
  const [openEditConfirm, setOpenEditConfirm] = useState(false)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const deleteRef = useRef()

  const handleEdit = useCallback(() => {
    let encoded = encodeURI(editValue)

    Query(`
      UPDATE tokyo_g_comments
      SET 
          description = '${encoded}',
          category = '${editCategory}'
      WHERE cartodb_id = ${editCartodbId}
      `)
      .then(() => {
        setRefreshTime()
      })
      .finally(() => {
        setOpenEditConfirm(false)
        setEditCartodbId(null)
      })
  }, [editCartodbId, editValue, editCategory])

  const handleEditClose = () => {
    setOpenEditConfirm(false)
  }

  const onDelete = (id) => {
    deleteRef.current = id
    setOpenDeleteConfirm(true)
  }

  const handleDelete = () => {
    Query(
      `UPDATE tokyo_g_comments 
            SET deleted_at = NOW(), deleted_user_id = ${authState.userData.detail.cartodb_id}
            WHERE cartodb_id = ${deleteRef.current}`
    )
      .then(() => {
        setRefreshTime()
        setOpenDeleteConfirm(false)
      })
      .catch((e) => {
        console.log(e)
        setOpenDeleteConfirm(false)
      })
  }

  const handleDeleteClose = () => {
    setOpenDeleteConfirm(false)
  }

  const list = useMemo(() => {
    return (
      data?.map((v, i) => {
        return (
          <Box
            key={i}
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "8px",
              border: "1px solid #000",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                backgroundColor: "#eee",
                padding: "16px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "120px",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #999",
                    borderRadius: "8px",
                    padding: "6px",
                    backgroundColor: "#fff",
                  }}
                >
                  {/* 6p */}
                  {editCartodbId !== v.cartodb_id && (
                    <Typography variant="caption">
                      {v.category ?? "その他"}
                    </Typography>
                  )}
                  {editCartodbId === v.cartodb_id && (
                    <Box className="category-edit">
                      <Select
                        variant="standard"
                        size="small"
                        value={editCategory}
                        onChange={(e) => setEditCategory(e.target.value)}
                      >
                        {Category.map((v) => {
                          return (
                            <MenuItem
                              value={v}
                              key={v}
                              style={{ fontSize: "14px" }}
                            >
                              {v}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  {dayjs(v.created_at).format("YYYY年M月D日(ddd) H時m分")}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">{v.name}</Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Box>
                <IconButton
                  onClick={() => {
                    setEditCartodbId(v.cartodb_id)
                    setEditValue(decodeURI(v.description))
                    setEditCategory(v.category)
                  }}
                  disabled={!!editCartodbId}
                >
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onDelete(v.cartodb_id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
            {editCartodbId !== v.cartodb_id && (
              <Box
                sx={{ padding: "8px" }}
                dangerouslySetInnerHTML={{
                  __html: decodeURI(v.description).replaceAll("\n", "<br />"),
                }}
              ></Box>
            )}
            {editCartodbId === v.cartodb_id && (
              <Box>
                <TextareaAutosize
                  placeholder="コメント入力..."
                  minRows={2}
                  autoFocus={true}
                  style={{
                    width: "calc(100% - 16px)",
                    flexGrow: 1,
                    border: 0,
                    margin: "8px",
                  }}
                  value={editValue}
                  onChange={(e) => {
                    console.log(e.target.value)
                    setEditValue(e.target.value)
                  }}
                />
                <Box>
                  <Button onClick={() => setOpenEditConfirm(true)}>保存</Button>
                  <Button onClick={() => setEditCartodbId(null)}>
                    キャンセル
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        )
      }) ?? []
    )
  }, [data, editCartodbId, editValue, editCategory])

  useEffect(() => {
    if (!props.treeId) {
      return
    }

    setData([])
    setLoading(true)
    try {
      Rows(
        `SELECT * FROM tokyo_g_comments_view WHERE tree_id = '${props.treeId}' AND type=9 ORDER BY created_at`
      )
        .then((rows) => {
          setData(rows)
          setLoading(false)
        })
        .catch((e) => {
          console.log(e)
          setLoading(false)
        })
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }, [props.treeId, state.refreshTime])

  useEffect(() => {
    if (!list || !messageEndRef.current) {
      return
    }

    scrollToBottom()
  }, [list, messageEndRef.current])

  const scrollToBottom = () => {
    setTimeout(() => {
      messageEndRef.current?.scrollIntoView()
    }, 100)
  }

  const send = () => {
    if ((value?.trim()?.length ?? 0) === 0) {
      return
    }
    let encoded = encodeURI(value)

    Query(
      `INSERT INTO tokyo_g_comments (tree_id, type, description, created_at, created_user_id, category) 
            VALUES ('${props.treeId}', 9, '${encoded}', NOW(), ${
        authState.userData.detail.cartodb_id
      }, '${category ?? "その他"}')`
    )
      .then((res) => {
        setValue("")
        setRefreshTime()
      })
      .catch((e) => {})
  }

  return (
    <Box
      sx={{ height: "100%", display: "flex", flexDirection: "column", gap: 1 }}
    >
      {!loading && data && (
        <Box sx={{ flexGrow: 1, overflowY: "auto" }} ref={listRef}>
          {list}
          <div ref={messageEndRef} />
        </Box>
      )}
      {!loading && !data && <Box sx={{ flexGrow: 1 }}>コメントなし</Box>}
      {loading && <Box sx={{ flexGrow: 1 }}>読込中...</Box>}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #000",
          borderRadius: "8px",
          justifyContent: "center",
        }}
      >
        <TextareaAutosize
          placeholder="コメント入力..."
          minRows={2}
          style={{
            width: "calc(100% - 16px)",
            flexGrow: 1,
            border: 0,
            margin: "8px",
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        ></TextareaAutosize>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              marginRight: "8px",
            }}
          >
            <Typography variant="subtitle2">カテゴリ選択:</Typography>
            <select
              id="category-select"
              onChange={(e) => {
                setCategory(e.target.value)
              }}
            >
              {Category.map((v) => (
                <option key={v}>{v}</option>
              ))}
            </select>
          </Box>
          <IconButton disabled={!value} sx={{ mr: "4px" }} onClick={send}>
            <SendIcon
              size="small"
              sx={{ color: value ? "#4343e1" : "gray", width: "24px" }}
            />
          </IconButton>
        </Box>
      </Box>
      <Dialog open={openDeleteConfirm} onClose={handleDeleteClose}>
        <DialogTitle>本当に削除してよろしいですか</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この操作は元に戻せません。本当に削除しますか
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleDelete} color="secondary">
              削除
            </Button>
            <Button onClick={handleDeleteClose}>キャンセル</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog open={openEditConfirm} onClose={handleEditClose}>
        <DialogTitle>編集の保存</DialogTitle>
        <DialogContent>
          <DialogContentText>
            編集を保存してよろしいですか。この操作は元に戻せません
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEdit} color="secondary">
            編集を保存
          </Button>
          <Button onClick={handleEditClose}>キャンセル</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

DialogDetailContentCommentView.propTypes = {
  treeId: PropTypes.string,
}

export default DialogDetailContentCommentView
