import React, { useContext, useEffect, useState } from "react"
import {
  AppBar,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import HomeIcon from "@mui/icons-material/Home"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import useLocalStorage from "../../../manager/localStorage"
import { makeStyles } from "@mui/styles"
import { RootDataContext, ViewType } from "../index"
import { AuthDataContext } from "../../../App"
import { WindowMode } from "../data/state"
import {
  BorderHorizontalRounded as BorderHorizontalRoundedIcon,
  BorderVerticalRounded as BorderVerticalRoundedIcon,
} from "@mui/icons-material"

const useStyles = makeStyles({
  toggle: {
    color: "#526981 !important",
    borderColor: "#76a1cb !important",
  },
  toggleButton: {
    borderColor: "white",
    color: "#99bde2 !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
  toggleButtonSelected: {
    backgroundColor: "#c8e0f7 !important",
    color: "#1e3176 !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
})

const Header = (props) => {
  const { title } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const { state, setFilterData, setViewType, setWindowMode } =
    useContext(RootDataContext)
  const { authState, logout } = useContext(AuthDataContext)
  const [isTitleHidden, setIsTitleHidden] = useState(false)

  useEffect(() => {
    console.log("AppBar", "auth", authState.userData)
  }, [authState.userData])

  useEffect(() => {
    window.addEventListener("resize", onReisze)
  }, [])

  const toHome = () => {
    navigate("/")
  }

  const onFilterReset = async () => {
    await setFilterData({})
  }

  const onChangeViewType = (event, newViewTypes) => {
    if (newViewTypes.length === 0) {
      return
    }
    setViewType(newViewTypes)
  }

  const onLogout = () => {
    authState.auth.signOut().then(() => {
      logout()
    })
  }

  const onOpenReceipt = () => {
    window.open("https://diagnostic.gairoju.jp/", "_blank")
  }

  const onChangeWindowMode = (e, value) => {
    console.log("[WindowMode]", "change", e, e.target.value, value)
    if (value) {
      setWindowMode(value)
    }
  }

  const onReisze = () => {
    console.log("[TitleHidden]", "check", window.innerWidth)
    setIsTitleHidden(window.innerWidth < 1540)
  }

  return (
    <Box>
      <AppBar
        position="static"
        color={
          (authState.userData?.detail?.type ?? 0) === 1
            ? "secondary"
            : "primary"
        }
      >
        <Toolbar>
          <Typography
            variant="h5"
            component="div"
            fontWeight="bold"
            style={{
              whiteSpace: "nowrap",
              fontFamily: "Zen Kaku Gothic New",
              overflow: "hidden",
            }}
          >
            {title || process.env.REACT_APP_TITLE || "データベース"}
          </Typography>

          {!props.disableHomeButton && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="home"
              sx={{ mr: 2 }}
              onClick={toHome}
            >
              <HomeIcon />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {props.onExport && (
            <Button
              variant="outlined"
              style={{
                whiteSpace: "nowrap",
                color: "white",
                borderColor: "white",
                overflow: "hidden",
              }}
              onClick={props.onExport}
            >
              Excel出力
            </Button>
          )}
          <Box sx={{ m: 2 }} />
          <Button
            variant="outlined"
            style={{
              whiteSpace: "nowrap",
              color: "white",
              borderColor: "white",
              overflow: "hidden",
            }}
            onClick={props.onLegends}
          >
            地図凡例表示
          </Button>
          <Box sx={{ m: 1 }} />
          {props.onSummary && (
            <Button
              variant="outlined"
              style={{
                whiteSpace: "nowrap",
                color: "white",
                borderColor: "white",
                overflow: "hidden",
              }}
              onClick={props.onSummary}
            >
              集計表表示
            </Button>
          )}
          <Box sx={{ m: 1 }} />
          <Button
            variant="outlined"
            style={{
              whiteSpace: "nowrap",
              color: "white",
              borderColor: "white",
              overflow: "hidden",
            }}
            onClick={onFilterReset}
          >
            条件クリア
          </Button>
          <Box sx={{ m: 2 }} />
          <ToggleButtonGroup
            className={classes.toggle}
            onChange={onChangeViewType}
            value={state.viewType}
            style={{ whiteSpace: "nowrap" }}
          >
            <ToggleButton
              className={classes.toggleButton}
              aria-label="right aligned"
              size="small"
              value={ViewType.List}
              classes={{
                selected: classes.toggleButtonSelected,
              }}
            >
              リスト
            </ToggleButton>
            <ToggleButton
              className={classes.toggleButton}
              size="small"
              aria-label="left aligned"
              value={ViewType.Map}
              classes={{
                selected: classes.toggleButtonSelected,
              }}
            >
              地図
            </ToggleButton>
          </ToggleButtonGroup>
          <Box sx={{ m: 1 }} />
          <Button
            variant="contained"
            style={{
              whiteSpace: "nowrap",
              backgroundColor: "#dce926",
              color: "#000",
              overflow: "hidden",
            }}
            onClick={onOpenReceipt}
          >
            診断カルテを開く
          </Button>
          <Box sx={{ m: 1 }} />
          <ToggleButtonGroup
            value={state.windowMode}
            size="small"
            exclusive={true}
            onChange={onChangeWindowMode}
            sx={{ border: "1px solid #fff", height: "35px" }}
            color="success"
          >
            <Tooltip title="左右分割">
              <ToggleButton
                value={WindowMode.Vertical}
                style={
                  state.windowMode === WindowMode.Vertical
                    ? { backgroundColor: "#c8e0f7" }
                    : { color: "#99bde2" }
                }
              >
                <BorderVerticalRoundedIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="上下分割">
              <ToggleButton
                value={WindowMode.Horizontal}
                style={
                  state.windowMode === WindowMode.Horizontal
                    ? { backgroundColor: "#c8e0f7" }
                    : { color: "#99bde2" }
                }
              >
                <BorderHorizontalRoundedIcon />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          <Box sx={{ m: 1 }} />
          <Button
            variant="outlined"
            style={{
              whiteSpace: "nowrap",
              color: "white",
              borderColor: "white",
            }}
            onClick={onLogout}
          >
            ログアウト
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  disableHomeButton: PropTypes.bool,
  onChangeViewMode: PropTypes.func,
  onSummary: PropTypes.func,
  onLegends: PropTypes.func,
  onExport: PropTypes.func,
}

export default Header
