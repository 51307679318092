const DialogSummaryTableGaikanShindanColumnDef = (options) => [
  {
    headerName: "路線番号",
    width: 90,
    children: [{ headerName: "", field: "rosen_no", width: 90 }],
  },
  {
    headerName: "路線名",
    children: [{ headerName: "", field: "rosen_name", width: 60 }],
  },
  {
    headerName: "樹種",
    children: [{ headerName: "", field: "name" }],
  },
  {
    headerName: "59cm以下",
    field: "rank_a",
  },

  {
    headerName: "60〜119cm",
    field: "rank_b",
    aggFunc: "sum",
    width: 100,
  },
  {
    headerName: "120〜239cm",
    field: "rank_c",
    aggFunc: "sum",
    width: 100,
  },
  {
    headerName: "240cm以上",

    field: "rank_d",
    aggFunc: "sum",
    width: 100,
  },
  {
    headerName: "路線合計",
    children: [
      {
        headerName: "",
        field: "rosen_sum",
        aggFunc: "sum",
        width: 90,
      },
    ],
  },
]

export default DialogSummaryTableGaikanShindanColumnDef
