const DialogSummaryTableKikiShindanColumnDef = (options) => [
  {
    headerName: "路線番号",
    width: 90,
    children: [{ headerName: "", field: "rosen_no", width: 90 }],
  },
  {
    headerName: "路線名",
    children: [{ headerName: "", field: "rosen_name", width: 60 }],
  },
  {
    headerName: "樹種",
    children: [{ headerName: "", field: "name" }],
  },
  {
    headerName: "90cm以下",
    field: "rank_a",
    aggFunc: "sum",
    width: 90,
  },
  {
    headerName: "90〜179cm以下",
    field: "rank_b",
    aggFunc: "sum",
    width: 100,
  },
  {
    headerName: "180cm以上",
    field: "rank_c",
    aggFunc: "sum",
    width: 100,
  },
  {
    headerName: "路線合計",
    children: [
      {
        headerName: "",
        field: "rosen_sum",
        aggFunc: "sum",
        width: 90,
      },
    ],
  },
]

export default DialogSummaryTableKikiShindanColumnDef
